import './editor.scss';
import './style.scss';

const { __ } = wp.i18n;
const { InspectorControls } = wp.blockEditor;
const { registerBlockType } = wp.blocks;
const { withSelect } = wp.data;
const {
	PanelBody,
	RangeControl,
	TextControl,
	ToggleControl,
	SelectControl,
} = wp.components;
const { decodeEntities } = wp.htmlEntities;
const { createElement, Fragment } = wp.element;

registerBlockType('pixelbound/sectors-grid', {
	title: __('sectors Grid', 'pixelbound-sectors'),
	icon: 'grid-view',
	keywords: [
		__('Block', 'pixelbound-sectors'),
		__('Posts', 'pixelbound-sectors'),
	],
	attributes: {
		imageAspectRatio: {
			type: 'string',
			default: '1:1',
		},
		sectorCategories: {
			type: 'boolean',
			default: true,
		},
		sectorExcerpt: {
			type: 'boolean',
			default: true,
		},
		sectorLink: {
			type: 'boolean',
			default: true,
		},
		sectorLinkLabel: {
			type: 'string',
			default: __('Find Out More', 'pixelbound-sectors'),
		},
		numberOfSectorsPerPage: {
			type: 'number',
			default: 6,
		},
		sectorColumnCount: {
			type: 'number',
			default: 3,
		},
		spacingTop: {
			type: 'number',
			default: 4,
		},
		spacingBottom: {
			type: 'number',
			default: 4,
		},
	},
	edit: withSelect((select, props) => {
		const { attributes } = props;
		const { numberOfSectorsPerPage } = attributes;

		const sectors = select('core').getEntityRecords('postType', 'pb_sector', {
			per_page: numberOfSectorsPerPage,
			orderby: 'date',
			order: 'desc',
		});

		const sectorsToReturn =
			sectors &&
			sectors.map((sector) => {
				let categoriesInfo = [];
				let featuredMediaDetails = null;

				// If the post has a featured image then let's get it and add it to out post object
				if (sector.featured_media) {
					featuredMediaDetails = select('core').getMedia(sector.featured_media);
				}

				// If the post is assigned to any categories then let's get them and add them to the post object
				if (sector.categories) {
					const categories = select('core').getEntityRecords(
						'taxonomy',
						'category',
						{
							per_page: -1,
						}
					);

					if (categories) {
						sector.categories.forEach((categoryID) => {
							categories.forEach((category) => {
								if (category.id === categoryID) {
									categoriesInfo.push({
										id: category.id,
										name: category.name,
										url: category.link,
									});
								}
							});
						});
					}
				}

				return { ...sector, featuredMediaDetails, categoriesInfo };
			});

		return {
			sectors: sectorsToReturn,
		};
	})((props) => {
		const { sectors, className, attributes, setAttributes } = props;
		const {
			imageAspectRatio,
			sectorCategories,
			sectorExcerpt,
			sectorLink,
			sectorLinkLabel,
			numberOfSectorsPerPage,
			sectorColumnCount,
			spacingTop,
			spacingBottom,
		} = attributes;

		// Construct inline styles object
		let inlineStyles = {};
		spacingTop ? (inlineStyles.marginTop = `${spacingTop}em`) : '0em';
		spacingBottom ? (inlineStyles.marginBottom = `${spacingBottom}em`) : '0em';

		return (
			<Fragment>
				<InspectorControls>
					<PanelBody
						title={__('Settings', 'pixelbound-sectors')}
						initialOpen={true}
					>
						<SelectControl
							label={__('Image Aspect Ratio', 'pixelbound')}
							options={[
								{
									label: __('Ratio 1:1', 'pixelbound-sectors'),
									value: '1:1',
								},
								{
									label: __('Ratio 4:3', 'pixelbound-sectors'),
									value: '4:3',
								},
								{
									label: __('Ratio 5:4', 'pixelbound-sectors'),
									value: '5:4',
								},
								{
									label: __('Ratio 16:9', 'pixelbound-sectors'),
									value: '16:9',
								},
							]}
							value={imageAspectRatio}
							onChange={(value) => setAttributes({ imageAspectRatio: value })}
						/>
						<ToggleControl
							label={__('Toggle Service Categories', 'pixelbound-sectors')}
							help={
								sectorCategories
									? __('Disable the sector categories', 'pixelbound-sectors')
									: __('Enable the sector categories', 'pixelbound-sectors')
							}
							checked={sectorCategories}
							onChange={(sectorCategories) =>
								setAttributes({ sectorCategories })
							}
						/>
						<ToggleControl
							label={__('Toggle Service Excerpt', 'pixelbound-sectors')}
							help={
								sectorExcerpt
									? __('Disable the sector excerpt', 'pixelbound-sectors')
									: __('Enable the sector excerpt', 'pixelbound-sectors')
							}
							checked={sectorExcerpt}
							onChange={(sectorExcerpt) => setAttributes({ sectorExcerpt })}
						/>
						<ToggleControl
							label={__('Toggle Service Link', 'pixelbound-sectors')}
							help={
								sectorLink
									? __('Disable the sector link', 'pixelbound-sectors')
									: __('Enable the sector link', 'pixelbound-sectors')
							}
							checked={sectorLink}
							onChange={(sectorLink) => setAttributes({ sectorLink })}
						/>
						<TextControl
							label={__('Service Button Label', 'pixelbound-sectors')}
							help={__(
								'Change the text that appears in the "Find Out More" buttons.',
								'pixelbound-sectors'
							)}
							value={
								sectorLinkLabel
									? sectorLinkLabel
									: __('Find Out More', 'pixelbound-sectors')
							}
							onChange={(sectorLinkLabel) => setAttributes({ sectorLinkLabel })}
						/>
						<hr />
						<RangeControl
							label={__('Number Of sectors', 'pixelbound-sectors')}
							help={__(
								'How many sectors should be included in the carousel.',
								'pixelbound-sectors'
							)}
							value={numberOfSectorsPerPage}
							onChange={(value) =>
								setAttributes({ numberOfSectorsPerPage: value })
							}
							step={1}
							min={1}
							max={48}
						/>
						<RangeControl
							label={__('Number of Columns', 'pixelbound-sectors')}
							help={__('How many columns should this grid have?')}
							value={sectorColumnCount}
							onChange={(value) => setAttributes({ sectorColumnCount: value })}
							step={1}
							min={1}
							max={6}
						/>
						<RangeControl
							label={__('Spacing Top', 'pixelbound-sectors')}
							value={spacingTop}
							onChange={(value) => setAttributes({ spacingTop: value })}
							step={0.1}
							min={0}
							max={20}
						/>
						<RangeControl
							label={__('Spacing Bottom', 'pixelbound-sectors')}
							value={spacingBottom}
							onChange={(value) => setAttributes({ spacingBottom: value })}
							step={0.1}
							min={0}
							max={20}
						/>
					</PanelBody>
				</InspectorControls>

				{sectors && sectors.length > 0 ? (
					<div
						className={`${className} has-${sectorColumnCount}-columns`}
						style={inlineStyles}
					>
						{sectors.map((sector) => (
							<article className={'wp-block-pixelbound-sector'} key={sector.id}>
								<div className={'wp-block-pixelbound-sector__inner'}>
									{sector.featuredMediaDetails && (
										<div
											className={`wp-block-pixelbound-sector__image ratio-${imageAspectRatio}`}
										>
											<a
												href={sector.link}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													backgroundImage: `url( ${sector.featuredMediaDetails.source_url})`,
												}}
											></a>
										</div>
									)}

									<h2 className={'wp-block-pixelbound-sector__title'}>
										<a
											href={sector.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{decodeEntities(sector.title.rendered)}
										</a>
									</h2>
									{sectorCategories && sector.categoriesInfo && (
										<div className={'wp-block-pixelbound-sector__meta'}>
											<ul className={'wp-block-pixelbound-sector__categories'}>
												{sector.categoriesInfo.map((category) => (
													<li>
														<a
															href={category.url}
															target="_blank"
															rel="noopener noreferrer"
														>
															{category.name}
														</a>
													</li>
												))}
											</ul>
										</div>
									)}
									{sectorExcerpt && sector.excerpt && (
										<div className={'wp-block-pixelbound-sector__excerpt'}>
											{sector.excerpt.raw}
										</div>
									)}
									{sectorLink && sector.link && (
										<a
											className={'wp-block-pixelbound-sector__link'}
											href={sector.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{sectorLinkLabel
												? sectorLinkLabel
												: __('Find Out More', 'pixelbound-sectors')}
										</a>
									)}
								</div>
							</article>
						))}
					</div>
				) : (
					<div>
						{sectors
							? __('No sectors to display.', 'pixelbound-sectors')
							: __('Loading...', 'pixelbound-sectors')}
					</div>
				)}
			</Fragment>
		);
	}),
	save: () => {
		return '';
	},
});
